{
  "autoUpdate": true,
  "emit": true,
  "alpha": {
    "start": 0.8,
    "end": 0.4
  },
  "scale": {
    "start": 0.2,
    "end": 0.6,
    "minimumScaleMultiplier": 0.5
  },
  "color": {
    "start": "#291207",
    "end": "#632C10"
  },
  "speed": {
    "start": 100,
    "end": 30,
    "minimumSpeedMultiplier": 1
  },
  "acceleration": {
    "x": 0,
    "y": 0
  },
  "maxSpeed": 0,
  "startRotation": {
    "min": 255,
    "max": 285
  },
  "noRotation": false,
  "rotationSpeed": {
    "min": 0,
    "max": 0
  },
  "lifetime": {
    "min": 0.5,
    "max": 1
  },
  "blendMode": "normal",
  "frequency": 0.008,
  "emitterLifetime": 0.15,
  "maxParticles": 8,
  "pos": {
    "x": 0,
    "y": 0
  },
  "addAtBack": false,
  "spawnType": "point"
}
