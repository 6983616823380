{
  "autoUpdate": true,
  "emit": true,
  "alpha": {
    "start": 0.8,
    "end": 0
  },
  "scale": {
    "start": 0.2,
    "end": 0.02,
    "minimumScaleMultiplier": 1
  },
  "color": {
    "start": "#FF9F00",
    "end": "#FFFF00"
  },
  "speed": {
    "start": 100,
    "end": 50,
    "minimumSpeedMultiplier": 0.2
  },
  "acceleration": {
    "x": 0,
    "y": 0
  },
  "maxSpeed": 0,
  "startRotation": {
    "min": 0,
    "max": 360
  },
  "noRotation": false,
  "rotationSpeed": {
    "min": 0,
    "max": 0
  },
  "lifetime": {
    "min": 0.2,
    "max": 0.8
  },
  "blendMode": "normal",
  "frequency": 0.001,
  "emitterLifetime": 0.1,
  "maxParticles": 10,
  "pos": {
    "x": 0,
    "y": 0
  },
  "addAtBack": false,
  "spawnType": "circle",
  "spawnCircle": {
    "x": 0,
    "y": 0,
    "r": 0
  }
}
