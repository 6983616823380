{
  "autoUpdate": true,
  "emit": false,
  "alpha": {
    "start": 0.62,
    "end": 0
  },
  "scale": {
    "start": 0.5,
    "end": 1.0,
    "minimumScaleMultiplier": 1.0
  },
  "color": {
    "start": "#fff191",
    "end": "#ff622c"
  },
  "speed": {
    "start": 100,
    "end": 100,
    "minimumSpeedMultiplier": 1
  },
  "acceleration": {
    "x": 0,
    "y": 0
  },
  "maxSpeed": 0,
  "startRotation": {
    "min": 165,
    "max": 175
  },
  "noRotation": false,
  "rotationSpeed": {
    "min": 50,
    "max": 50
  },
  "lifetime": {
    "min": 0.1,
    "max": 0.5
  },
  "blendMode": "normal",
  "frequency": 0.001,
  "emitterLifetime": -1,
  "maxParticles": 1000,
  "pos": {
    "x": 0,
    "y": 0
  },
  "addAtBack": false,
  "spawnType": "circle",
  "spawnCircle": {
    "x": 0,
    "y": 0,
    "r": 10
  }
}
